import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import BlockContent from "@sanity/block-content-to-react"
import SmallAbout from "../../content/assets/testaboutbg.jpg"
import Layout from "../components/layout"
import SEO from "../components/seo"
import MainHeader from "../components/header-components/mainHeader"
import SocialFooter from "../components/socialBox/socialFooter"
import { colors } from "../components/colors/colors"

const AboutContainer = styled.div`
  height: 100%;
  min-height: 100vh;
  width: 100vw;
  display: grid;
  grid-auto-rows: 125px 1fr 75px;
  grid-template-columns: 1fr;
  background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url("${SmallAbout}");
  background-size: cover;
  background-repeat: no-repeat;

  @media (max-width: 811px){
    height: 100%;
  }
`
const AboutText = styled.section`
  width: 100%;
  max-width: 1300px;
  margin: 0 auto;
  color: ${colors.white};
  grid-row: 2/3;
  @media (max-width: 1000px) {
    margin: 0;
  }

  @media (max-width: 811px) {
    padding: 10px;
    margin-top: 30px;
  }
`

const AboutArticle = styled.article`
  padding: 10px;
  width: 80%;
  display: flex;
  flex-direction: column;
  > h2 {
    font-family: "Velo Sans Text";
  }

  @media (max-width: 811px) {
    width: 100%;
    padding: 10px;
  }
`

const TextHolder = styled.div`
  width: 90%;
  align-self: flex-start;

  > p {
    line-height: 1.4em;
    padding: 20px 0 20px 0;
  }
  @media (max-width: 811px) {
    width: 100%;
    font-size: 18px;
    font-weight: 400;
  }
`

const AboutPage = ({ data }) => {
  // const { site ,sanityPage } = data ?? {};
  // debugger;
  // const {title, _rawPageText: text} = sanityPage ?? {};
  // const {siteMetadata} = site ?? {};
  const testOne = `StraightEIGHT is a production company drawing on a diverse background of experiences and clientele to create dynamic work that stands out. StraightEIGHT is a group of scrappy artists, cinematographers, creators, designers, and editors all working towards a clear vision of creating work that brings audiences closer with human centered filmmaking.`
  const testTwo = `At the head of this team is Matthew J. Clark. Matthew truly understands the collaborative process of filmmaking. He is able to get to the heart of a concept or story, effectively communicate a clear vision that will motivate a team of professionals, and then calmly, methodically and tirelessly lead and serve this process from the conception of the idea to its final delivery. The results speak for themselves both technically and conceptually – projects with maximum visual impact that effect emotion, provoke thought and inspire persuasion.`
  return (
    <Layout>
      <SEO title="About" />
      <AboutContainer>
        <MainHeader />
        <AboutText>
          <AboutArticle>
            <h2>{`About StraightEight Films`}</h2>
            <TextHolder>
             <div>
                  <p dangerouslySetInnerHTML={{__html: testOne}}/>
                  <p dangerouslySetInnerHTML={{__html: testTwo}}/>
                </div>
            </TextHolder>
          </AboutArticle>
        </AboutText>
        <SocialFooter />
      </AboutContainer>
    </Layout>
  )
}

export default AboutPage

// export const pageQuery = graphql`
//   query {
//     site {
//       siteMetadata {
//         title
//       }
//     }
//     sanityPage(title: { eq: "About StraightEight" }) {
//       title
//       _rawPageText
//     }
//   }
// `
